import React from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import { Statistic } from '../../../helpers/uiComponents';
import { numberToLocal, secondsToTime } from '../../../helpers/uiFunctions';
import { errorMessage } from '../../../helpers/uiHelpers';
import { loadAnalyticsQuickStats, loadAnalyticsQuickStatsGA4 } from '../../data/supermetricsService';

class AnalyticsQuickStat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            analytics: null,
        }
    }
    componentDidMount() {
        loadAnalyticsQuickStatsGA4(
            this.props.endpoints,
            this.props.dates,
            this.props.currentClient.clientCode,
            localStorage.getItem('accessToken')
        ).then(response => {
            if (response.success) {
                var keys = response.result.data[0];
                var values = response.result.data[1];
                var data = {};
                keys.forEach((key, i) => data[key] = values[i]);
                this.setState({
                    analytics: data
                });
            } else {
                this.setState({analytics: errorMessage});
            }
        });
    }
    render() {
        const { analytics } = this.state;
        return(
            <React.Fragment>
                {(this.props.onClick) && (
                    <Router>
                        <Link to="metrics-ga4analytics" className="button white learn-more" onClick={() => this.props.onClick("metrics-ga4analytics")}>
                            <i className="fas fa-chart-line"></i>
                        </Link>
                    </Router>
                )}
                <h3>GOOGLE ANALYTICS</h3>
                <section className="stats row">
                    {(analytics && analytics.message) ? (
                        <p>There may have been an error connecting to Analytics, or Analytics is not configured for your Portal.</p>
                    ) : (
                        <React.Fragment>
                            <Statistic
                                label="Sessions"
                                value={(analytics) ? (numberToLocal(analytics['Sessions'])) : ("calculating..")}
                                status={(analytics && (analytics['Change % Sessions'] < 0)) ? ("down") : ("up")}
                                percent={(analytics) ? (analytics['Change % Sessions']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label="Unique Users"
                                value={(analytics) ? (numberToLocal(analytics['Total users'])) : ("calculating..")}
                                status={(analytics && (analytics['Change % Total users'] < 0)) ? ("down") : ("up")}
                                percent={(analytics) ? (analytics['Change % Total users']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            <Statistic
                                label="Page Views"
                                value={(analytics) ? (numberToLocal(analytics['Views'])) : ("calculating..")}
                                status={(analytics && (analytics['Change % Views'] < 0)) ? ("down") : ("up")}
                                percent={(analytics) ? (analytics['Change % Views']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            />
                            {/* <Statistic
                                label="Average Session Time"
                                value={(analytics) ? (secondsToTime(analytics['Average session duration'])) : ("calculating..")}
                                status={(analytics && (analytics['Change % Average session duration'] < 0)) ? ("down") : ("up")}
                                percent={(analytics) ? (analytics['Change % Average session duration']) : (0)}
                                hideHistorical={this.props.hideHistorical}
                            /> */}
                            <Statistic label="Avg. Engagement Time"
                                    value={
                                        (analytics) ? (secondsToTime(analytics['Average session length (sec)'])) : ("calculating..")}
                                    status={
                                        (analytics && (analytics['Change % Average session length (sec)'] < 0)) ? ("down") : ("up")}
                                    percent={
                                        (analytics) ? (analytics['Change % Average session length (sec)']) : (0)}
                                    hideHistorical={this.props.hideHistorical}
                                />
                        </React.Fragment>
                    )}
                </section>
            </React.Fragment>
        )
    }
}
export default AnalyticsQuickStat;